<template>
  <div class="p-6 _2xl_container _2xl_mx-auto" :style="`background:${specials[currentIndex].colour};`">
    <div
      id="specialContainer"
      class="
        grid
        items-center
        relative
      "
    >
      <a
        class="cursor-pointer"
        :href="`/shop/specials/${specials[currentIndex].slug}`"
      >
        <div
          class="specialImage w-full rounded shadow"
          :style="`
          background: url(${imageUrl}) no-repeat center;
          background-size: cover; aspect-ratio: ${image.aspect_ratio}
        `"
        ></div>
      </a>
      <div
        class="
          absolute
          bottom-4
          _2xl_bottom-12
          left-0
          right-0
          flex
          gap-4
          justify-center
        "
        v-if="specials.length > 1"
      >
        <button
          v-for="(special, index) in specials"
          :key="special.id"
          @click="selectIndex(index)"
          class="rounded-full shadow bg-gray-100 p-4"
          :class="{ 'bg-pink-500': index === currentIndex }"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import tinycolor from "tinycolor2";
import ChevronLeftIcon from "./ChevronLeftIcon.vue";
import ChevronRightIcon from "./ChevronRightIcon.vue";
import ImageService from "../services/imageService";
export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    specials: Array,
  },
  data() {
    return {
      currentIndex: 0,
      screenWidth: null,
      height: 0,
    };
  },
  computed: {
    backgroundColour() {
      return tinycolor(this.specials[this.currentIndex].colour);
    },
    textColour() {
      return this.backgroundColour.isDark() ? "text-white" : "text-gray-900";
    },
    image() {
      return this.specials[this.currentIndex].image;
    },
    imageUrl() {
      return ImageService.url(this.image);
    },
  },
  methods: {
    increaseCurrentIndex(index) {
      setInterval(() => {
        if (this.currentIndex === index) {
          this.currentIndex =
            this.currentIndex + 1 < this.specials.length
              ? (this.currentIndex += 1)
              : 0;
        }
        this.increaseCurrentIndex(this.currentIndex);
      }, 8000);
    },
    selectIndex(index) {
      this.currentIndex = index;
    },
  },
  mounted() {
    if (this.specials.length > 1) {
      this.increaseCurrentIndex(0);
    }
  },
};
</script>
<style scoped>
.specialImage {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
</style>
