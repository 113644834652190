<template>
    <dialog-modal ref="modal" :processing="show_spinner" message="" :button_classes="`button button-md button-primary`">
    <template v-slot:button>
      <span>Write a {{ this.comment_id ? "Reply" : "Comment" }}</span>
    </template>
    <template v-slot:header>
        {{ `Write a ${ this.comment_id ? 'Reply' : 'Comment' }` }}
      </template>
      <form @submit.prevent="validateForm" class="modal__formcontainer">
        <div class="overflow-y-auto overscroll-contain h-full grid gap-8 p-8">
          <div class="flex flex-col gap-4">
            <div class="flex flex-col sm_flex-row gap-4" v-if="!auth">
              <div class="flex-1">
                <form-input
                  id="name"
                  v-model="name"
                  :error="errors['name']"
                  :required="true"
                  >Name</form-input
                >
              </div>
            </div>

            <div class="flex-1">
              <form-textarea
                id="comment"
                v-model="comment"
                :error="errors['comment']"
                :required="true"
                >Comment</form-textarea
              >
            </div>
          </div>
        </div>

        
      </form>
  <template v-slot:submit_button>
          <button class="inline-flex items-center px-4 py-2 bg-green-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-green-500 active:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition ease-in-out duration-150"
          :class="{ 'opacity-25': show_spinner }" :disabled="show_spinner" @click.prevent="validateForm"
          >
              Confirm
          </button>
      </template>

  </dialog-modal>
</template>

<script>
import FormTextarea from "./FormTextarea.vue";
import FormInput from "./FormInput.vue";
import Checkbox from "./Checkbox.vue";
import DialogModal from "./DialogModal.vue"

export default {
  components: {
    FormInput,
    FormTextarea,
    Checkbox,
    DialogModal,
  },
  emits: ["close", "refresh"],
  props: {
    slug: String,
    comment_id: Number,
    auth: Object,
  },
  data() {
    return {
      show_spinner: false,
      renew: false,
      name: this.auth ? `${this.auth.first_name} ${this.auth.last_name}` : "",
      email: this.auth?.email ?? "",
      comment: "",
      errors: {},
      error_message: "",
    };
  },
  computed: {},
  methods: {
    formData() {
      const data = {
        name: this.auth ? `${this.auth.first_name} ${this.auth.last_name}` : this.name,
        comment: this.comment,
      };
      return data;
    },
    validateForm() {
      this.error_message = "";
      this.errors = {};
      if (!this.name && !this.auth) {
        this.errors["name"] = "Please enter your name";
      }
      if (!this.comment) {
        this.errors["comment"] = "Please enter a review";
      }
      if (!Object.keys(this.errors).length) {
        this.submitForm();
      } else {
        this.error_message =
          "Please correct the missing inputs in the form and try again";
      }
    },
    submitForm() {
      this.show_spinner = true;
      axios({
        method: "post",
        url: this.comment_id ? `/blog/${this.slug}/${this.comment_id}/comments` : `/blog/${this.slug}/comments`,
        data: this.formData(),
      })
        .then((response) => {
          this.endForm();
          this.$refs.modal.toggleModal()
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.error_message = "Please fix the errors and try again";
            console.log(error.response.data.errors);
            this.errors = Object.keys(error.response.data.errors).reduce(
              (errors, key) => {
                errors[key] = error.response.data.errors[key][0];
                return errors;
              },
              {}
            );
          } else if (
            error.response.status >= 500 &&
            error.response.status < 600
          ) {
            this.error_message = error.response.data.message;
          }
          this.show_spinner = false;
        });
    },
    clearForm() {
      this.comment = "";
    },
    endForm() {
      this.flash();
      this.clearForm();
      this.$emit("refresh");
    },
    flash() {
      this.eventBus.emit("flashMessage", {
        message: `Thank you for your comment`,
        error: false,
      });
    },
  },
};
</script>