<template>
  <div :style="`background-color: ${colour};`" v-if="testimonials.length">
    <section class="_2xl_container _2xl_px-0 _2xl_mx-auto grid">
      <div class="w-full grid grid-cols-1 p-4 py-12 gap-4">
        <h3 class="text-2xl font-bold text-primary-500">
          What our Customers are saying
        </h3>
        <ul
          class="w-full overflow-hidden whitespace-nowrap"
          :class="`chunk-testimonials`"
        >
          <li
            class="inline-block w-full"
            v-for="(chunk, i) in testimonials"
            :key="`chuck${i}`"
          >
            <div
              class="w-full tagChunk grid grid-cols-1 gap-4 whitespace-normal"
            >
              <p class="text-lg italic font-bold">"{{ chunk.quote }}"</p>
              <p class="text-xs">{{ chunk.author }}</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import tinycolor from "tinycolor2";
export default {
  props: {
    testimonials: Array,
  },
  data() {
    return {
      index: 0,
      screenWidth: null,
      colour: "#ffffff",
    };
  },
  computed: {
    backgroundColour() {
      return tinycolor(this.tag.colour);
    },
    textColour() {
      return this.backgroundColour.isDark() ? "text-white" : "text-gray-900";
    },
    productColour() {
      return this.backgroundColour.lighten(50).toHexString();
    },
  },
  methods: {
    listElement() {
      return document.querySelector(`ul.chunk-testimonials`);
    },
    listElementWidth() {
      return window.getComputedStyle(this.listElement()).width;
    },
    increaseIndex() {
      if (this.index + 1 < this.testimonials.length) {
        this.index++;
        this.listElement().scroll({
          top: 0,
          left: this.listElementWidth().replace("px", "") * this.index,
          behavior: "smooth",
        });
      } else {
        this.index = 0;
        this.listElement().scroll({
          top: 0,
          left: -this.listElement().scrollLeft,
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.index = 0;
      this.listElement().scroll({
        top: 0,
        left: -this.listElement().scrollLeft,
        behavior: "smooth",
      });
    });
    setInterval(() => this.increaseIndex(), 8000);
  },
};
</script>
<style scoped>
</style>
