<template>
  <section class="grid gap-4 py-4">
    <h3
      class="
        font-semibold
        text-2xl text-gray-800
        leading-tight
        flex
        gap-4
        justify-between
        items-center
      "
    >
      <span>Comments</span>
      <blog-comment-form
        :slug="slug"
        :comment_id="null"
        :auth="auth"
        :is_modal="true"
        @refresh="getCommentsData"
      ></blog-comment-form>
    </h3>

    <div class="grid gap-4">
      <div
        class="
          flex flex-col
          sm_flex-row sm_justify-between
          gap-2
          sm_items-center
          p-4
          bg-gray-100
          rounded
        "
      >
        <div class="text-xs">
          {{ pagination.from ?? 0 }} to {{ pagination.to ?? 0 }} of
          {{ pagination.total }} Comments
        </div>
      </div>
      <div class="grid">
        <div class="grid gap-2" v-if="!show_spinner && comments.length">
          <article
            v-for="comment in comments"
            :key="comment.id"
            class="rounded shadow p-4 grid gap-2"
          >
            <p class="text-xxxs">
              {{ comment.name }} - {{ getRelativeTime(comment.created_at) }}
            </p>
            <h4 class="font-bold">{{ comment.title }}</h4>
            <p>{{ comment.comment }}</p>
            <div
              v-for="reply in comment.replies"
              :key="reply.id"
              class="bg-gray-50 rounded grid gap-2 p-4"
            >
              <p class="text-xxxs">
                {{ reply.name }} - {{ getRelativeTime(reply.created_at) }}
              </p>
              <p>{{ reply.comment }}</p>
            </div>
            <div class="rounded-b -mb-4 -mx-4 p-4 bg-gray-100">
              <blog-comment-form
                :slug="slug"
                :comment_id="comment.id"
                :auth="auth"
                :is_modal="true"
                @refresh="getCommentsData"
              ></blog-comment-form>
            </div>
          </article>
        </div>
        <div
          class="p-4 text-center"
          v-else-if="!comments.length && !show_spinner"
        >
          Sorry, there are no comments to show.
        </div>
        <div v-else class="grid place-items-center p-4 table-spinner">
          <spinner-icon></spinner-icon>
        </div>
      </div>
      <div
        class="flex justify-between gap-2 items-center p-4 bg-gray-100 rounded"
      >
        <div class="text-xs">
          {{ pagination.from ?? 0 }} to {{ pagination.to ?? 0 }} of
          {{ pagination.total }} Comments
        </div>
        <div class="flex gap-2 items-center">
          <button
            class="button button-light button-xs"
            @click="changePage(page - 1)"
            :disabled="!pagination.prev_page_url"
          >
            Previous
          </button>

          <button
            class="button button-light button-xs"
            @click="changePage(page + 1)"
            :disabled="!pagination.next_page_url"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import SpinnerIcon from "./icons/SpinnerIcon.vue";
import BlogCommentForm from "./BlogCommentForm.vue";

export default {
  props: ["slug", "auth"],
  components: {
    SpinnerIcon,
    BlogCommentForm,
  },
  data() {
    return {
      show_spinner: true,
      comments: [],
      page: 1,
      pagination: {
        current_page: null,
        first_page_url: null,
        from: null,
        last_page: null,
        last_page_url: null,
        links: [],
        next_page_url: null,
        per_page: null,
        prev_page_url: null,
        to: null,
        total: null,
      },
      sort_option: "",
      filter_option: "",
      sort: "created_at",
      descending: "desc",
      filters: {},
      sort_options: [],
      filter_options: [],
    };
  },
  computed: {},
  watch: {
    sort_option(val) {},
    filter_option(val) {},
  },
  methods: {
    getRelativeTime(date) {
      return dayjs().to(dayjs(date));
    },
    changePage(new_page) {
      this.page = new_page;
      this.getCommentsData();
    },
    getCommentsData() {
      this.show_spinner = true;
      axios({
        method: "get",
        url: `/blog/${this.slug}/comments`,
        data: {
          filters: this.filters,
          sort: this.sort,
          descending: this.descending,
          page: this.page,
        },
      })
        .then(({ data }) => {
          this.comments = data.comments.data;
          this.pagination.current_page = data.comments.current_page;
          this.pagination.first_page_url = data.comments.first_page_url;
          this.pagination.from = data.comments.from;
          this.pagination.last_page = data.comments.last_page;
          this.pagination.last_page_url = data.comments.last_page_url;
          this.pagination.links = data.comments.links;
          this.pagination.next_page_url = data.comments.next_page_url;
          this.pagination.per_page = data.comments.per_page;
          this.pagination.prev_page_url = data.comments.prev_page_url;
          this.pagination.to = data.comments.to;
          this.pagination.total = data.comments.total;
          this.show_spinner = false;
          this.average = data.average;
          this.total_5_star = parseInt(data.total_5_star);
          this.total_4_star = parseInt(data.total_4_star);
          this.total_3_star = parseInt(data.total_3_star);
          this.total_2_star = parseInt(data.total_2_star);
          this.total_1_star = parseInt(data.total_1_star);
          this.show_spinner = false;
          console.log(data);
        })
        .catch((error) => {
          this.show_spinner = false;
        });
    },
  },
  mounted() {
    this.getCommentsData();
  },
};
</script>
